/*
 * jsGrid v1.5.3 (http://js-grid.com)
 * (c) 2016 Artem Tabalin
 * Licensed under MIT (https://github.com/tabalinas/jsgrid/blob/master/LICENSE)
 */

.jsgrid {
    position: relative;
    overflow: hidden;
    font-size: 1em;
}

.jsgrid, .jsgrid *, .jsgrid *:before, .jsgrid *:after {
    box-sizing: border-box;
}

.jsgrid input,
.jsgrid textarea,
.jsgrid select {
    font-size: 1em;
}

.jsgrid-grid-header {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.jsgrid-grid-body {
    overflow-x: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.jsgrid-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
}

.jsgrid-cell {
    padding: 0.5em 0.5em;
}

.jsgrid-Ñell,
.jsgrid-header-cell {
    box-sizing: border-box;
}

.jsgrid-align-left {
    text-align: left;
}

.jsgrid-align-center,
.jsgrid-align-center input,
.jsgrid-align-center textarea,
.jsgrid-align-center select {
    text-align: center;
}

.jsgrid-align-right,
.jsgrid-align-right input,
.jsgrid-align-right textarea,
.jsgrid-align-right select {
    text-align: right;
}

.jsgrid-header-cell {
    padding: .5em .5em;
}

.jsgrid-filter-row input,
.jsgrid-filter-row textarea,
.jsgrid-filter-row select,
.jsgrid-edit-row input,
.jsgrid-edit-row textarea,
.jsgrid-edit-row select,
.jsgrid-insert-row input,
.jsgrid-insert-row textarea,
.jsgrid-insert-row select {
    width: 100%;
    padding: .3em .5em;
}

.jsgrid-filter-row input[type='checkbox'],
.jsgrid-edit-row input[type='checkbox'],
.jsgrid-insert-row input[type='checkbox'] {
    width: auto;
}


.jsgrid-selected-row .jsgrid-cell {
    cursor: pointer;
}

.jsgrid-nodata-row .jsgrid-cell {
    padding: .5em 0;
    text-align: center;
}

.jsgrid-header-sort {
    cursor: pointer;
}

.jsgrid-pager {
    padding: .5em 0;
}

.jsgrid-pager-nav-button {
    padding: .2em .6em;
}

.jsgrid-pager-nav-inactive-button {
    display: none;
    pointer-events: none;
}

.jsgrid-pager-page {
    padding: .2em .6em;
}
