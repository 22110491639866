$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';

// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
// $brand-primary: #3097D1;
$brand-primary: #66bb6a;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Roboto", sans-serif;
$font-size-base: 14px;
$line-height-base: 1;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;
$top-narbar-color: #01579b;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;


/* Palette generated by Material Palette - materialpalette.com/blue/blue-grey */
$primary-color-dark: #1976D2;
$primary-color: #2196F3;
$primary-color-light: #BBDEFB;
$primary-color-text: #FFFFFF;
$accent-color: #607D8B;
$primary-text-color: #212121;
$secondary-text-color: #757575;
$divider-color: #BDBDBD;


$azure: #F8F9F9;
$light-grey: #D6D5D5;
$pattens-blue: #DEE1E4;
$trout: #515253;
$summer-sky: #01CBFE;
$green-400: #66bb6a;
$green-600: #388e3c;
$cloudy: #A6A4A1;
$white: #FFFFFF;
$pagination-active-bg: #66bb6a;
$pagination-active-border: $green-600;
$mdb-checkbox-checked-color: $green-400;
$mdb-checkbox-checked-color: $green-600;

$menu-active-background: rgba(0,0,0,0.04);

$profile-popup-font-size: 12px;

$default-body-light-color: #fff;
