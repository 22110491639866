.pto-sheet {
  padding: 25px!important;
  border: 1px solid #E9EDEF
}
.pto-sheet .pto-general-text {
  color: #738081
}
.pto-block {
  margin: 10px auto 20px auto;
}
.pto-block-heading {
  border-bottom: 1px solid #525E64;
  text-transform: uppercase;
  font-size: 18px;
  padding: 5px 0;
  color: #525E64
}
.pto-block-body {

}
.pto-row {
  width: auto;
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 10px;
  color: #738081
}
#btn_show_all {
  display: block;
  text-transform: lowercase;
}
#btn_show_less {
  display: none;
  text-transform: lowercase;
}
#btn_show_all.pto-btn-hidden,
#btn_show_less.pto-btn-hidden {
  display: none;
}
.pto-note {
  margin-bottom: 10px;
  display: none;
}
.pto-note.pto-first-note {
  display: block;
}
.pto-note .pto-note-name strong:after {
  width: 3px;
  height: 3px;
  margin: 3px 4px 3px 7px;
  content: '';
  background: #738081;
  border-radius: 50%;
  display: inline-block;
}
.pto-detail-block {
  margin: 10px auto 20px auto
}
.pto-detail-block-heading {
  font-size: 16px;
  padding: 5px 0 10px 0;
  color: #525E64
}
.pto-detail-block-heading::before {
  width: 10px;
  height: 10px;
  margin: 5px;
  content: '';
  background: #525E64;
  border-radius: 50%;
  float: left;
}
.pto-detail-block-body {

}
.pto-block-quote {
  background: #fbfbfb;
  border-left: 2px solid #ddd;
  padding: 0.5em 5px;
  quotes: "\201C""\201D""\2018""\2019"

}
.pto-block-quote:before {
  color: #ccc;
  content: "\â";
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em
}
.pto-block-quote p {
  display: inline
}
.pto-reference {
  word-wrap: break-word
}

/* Customize fullcalendar layout */
.pto-sheet .fc th,
.pto-sheet .fc td {
  border-top: none;
  border-bottom: none;
  background: #EDEFF5;
  padding: 5px 0 0 0
}
.pto-sheet tr:first-child > td > .fc-day-grid-event {
  color: #43a047;
  font-size: .65em;
  border-radius: 15px;
  border: 1px solid #43a047;
  background-color: #43a047;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
}
.pto-sheet tr:first-child > td > .fc-day-grid-event.morning {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pto-sheet tr:first-child > td > .fc-day-grid-event.lunch {
  color: #ffffff;
  border-radius: 2px;
  border: 1px solid #29c6da;
  background-color: #29c6da;
  text-align: center
}
.pto-sheet tr:first-child > td > .fc-day-grid-event.afternoon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pto-sheet tr:first-child > td > .fc-day-grid-event.disabled {
  color: #ffffff;
  border: 1px solid #d4d4d4;
  background-color: #d4d4d4;
  box-shadow: none
}
.pto-sheet .pto-detail-calendar-long-range {
  display: none
}
.pto-sheet .intermittent {
  border: 1px solid #ddd;
  color: #3c4858;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 5px
}
.pto-sheet .intermittent .fc th, .pto-sheet .intermittent .fc td {
  border-width: 0
}
.pto-sheet .pto-detail-calendar-long-range .fc-day-grid-container,
.pto-sheet .pto-detail-calendar-intermittent .fc-day-grid-container {
  overflow: hidden;
  height: 64px
}
.pto-sheet .pto-detail-calendar-long-range .fc-basic-view .fc-body .fc-row,
.pto-sheet .pto-detail-calendar-intermittent .fc-basic-view .fc-body .fc-row {
  height: 64px
}
.select2-container {
  width: 100% !important;
}

.cancelling-status {
  padding-left: 20px;
}

.cancelling-status:before {
  width: 15px;
  height: 15px;
  background: #ffffff;
  color: #333333;
  content: ' C ';
  border-radius: 3px;
  text-align: center;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
}

.total-team-waiting-requests,
.total-employee-waiting-requests {
  font-size: 10px;
  border-radius: 50%;
  vertical-align: middle;
  padding: .5em .7em .4em;
  text-align: right;
}

.modal {
  text-align: center;
}
.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  border: 1px solid #ececec;
  border-radius: 4px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal .panel {
  margin-bottom: 0;
}
.loading-square {
  z-index: 999999999;
}
.block {
  display: block !important;
  margin-top: 4px;
}
.work-break-all {
  word-break: break-all;
}
label.error {
  text-align: left;
}
.modal-content .modal-header {
  padding: 12px 18px 9px 18px;
}
.modal-content .modal-body {
  padding: 12px 18px 9px 18px;
}
.modal-content .modal-header + .modal-body {
  border-top: 1px solid #f7f7f7;
}
.modal-content .modal-footer {
   padding: 18px;
 }
.modal-content .modal-body + .modal-footer {
  padding-top: 9px;
  border-top: 1px solid #f7f7f7;
}
.font-italic {
  font-style: italic !important;
}

.center-avatar {
  width: 90px;
  margin: 0 auto;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

/* Button groups */
.btn.btn-raised:not(.btn-link).active {
  background-color: #7fc8a7 !important;
}

/*===Start CSS for Select Two ===*/

.select-two-box, .select-two-label {
  padding:10px 5px 0 0;
}
.btn-calculate, .year-select-box {
  padding:0;
}

/**
 * Basic styles
 */
:focus {
  outline: none;
}
:disabled {
  background-color: transparent;
}

/**
* Multiple Select2
*/
.select2-container--material {
  width: 100% !important;
  ::placeholder {
      color: inherit;
  }

  /**
   * Textbox
   */  
  .select2-selection {
    overflow: visible;
    touch-action: manipulation;
    margin: 0;
    line-height: inherit;
    border-radius: 0;
    box-sizing: inherit;
    display: block;
    width: 100%;
    color: #55595c;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    padding: .5rem 0 .6rem;
    line-height: 1.5;
    background-color: transparent;
    background-image: none;
    border-radius: 0;
    margin-top: .2rem;
    margin-bottom: 1rem;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    outline: 0;
    width: 100%;
    box-shadow: none;
    transition: all .3s;
    min-height: 2.1rem;
    .select2-selection__rendered {
        padding-left: 0;
    }
  }
  
  .select2-selection--single {
      .select2-selection__rendered {
          float: left;
      }
      .select2-selection__arrow {
          float: right;
      }
  }
  
  .select2-selection--multiple {
    margin:0;
    padding:0;
    .select2-selection__rendered {
      width: 100%;
      li {
        list-style: none;
      }
      margin: 0;
      
    }
    /**
     * Multiple selected options
     */
    .select2-selection__choice {
      /* @extend .mdl-chip */
      height: 32px;
      //font-family: "Roboto","Helvetica","Arial",sans-serif;
      line-height: 32px;
      padding: 0 12px;
      border: 0;
      border-radius: 16px;
      background-color: #dedede;
      display: inline-block;
      color: rgba(0,0,0,.87);
      margin: 2px 0;
      font-size: 0;
      white-space: nowrap;
      
      /* @extend .mdl-chip__text */
      font-size: 13px;
      vertical-align: middle;
      display: inline-block;
      float: left;
      margin-right: 8px;
      margin-bottom: 4px;
    }
    
    /**
     * Multiple selected option clear button
     */
    .select2-selection__choice__remove {
      /* Hide default content */
      font-size: 0;
      opacity: 0.38;
      cursor: pointer;
      float: right;
      margin-top: 4px;
      margin-right: -6px;
      margin-left: 6px;
      transition: opacity;
      &::before {
        content: "x";
        /* @extend .material-icons */
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 20px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        color: #000;
      }
      &:hover {
          opacity: 0.54;
      }
    }
  }

  .select2-search--inline {
    .select2-search__field {
      width: 100%;
      margin-top: 0;
      height: 34px;
      line-height: 1;
    }
  }
  
  /* Dropdown*/
  .select2-dropdown {
    border: 0;
    .select2-search__field {
        min-height: 2.1rem;
        margin-bottom: 16px;
        border: 0;
        border-bottom: 1px solid #ccc;
        transition: all .3s;
        &:focus {
          border-bottom: 1px solid #3097D1;
          box-shadow: 0 1px 0 0 #3097D1;
        }
    }
  }
  
  .select2-results__options {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    background-color: #fff;
    margin: 0;
    min-width: 100px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 999;
    will-change: width,height;
  }
  
  /*Options*/
  .select2-results__option {
    clear: both;
    color: rgba(0,0,0,.87);
    line-height: 1.5rem;
    text-align: left;
    text-transform: none;
    display: block;
    padding: 1rem;

    /* Disabled options*/
    &[aria-disabled=true] {
      color: rgba(0,0,0,.3);
      background-color: transparent!important;
      cursor: context-menu;
      cursor: not-allowed;
    }
    
    /*Selected option*/
    &[aria-selected=true] {
      color: #2FB0C6;
      background-color: #eee;
    }
    
    /*Active/hovered option*/
    &--highlighted[aria-selected] {
      background-color: #ddd;
    }
  }
  
  /*Focused textbox*/
  &.select2-container--focus {
    .select2-selection {
      border-bottom: 1px solid #2FB0C6;
      box-shadow: 0 1px 0 0 #2FB0C6;
    }
  }

  /**
   * Disabled textbox
  */
  &.select2-container--disabled {
    .select2-selection {
      /* @extend .select-wrapper input.select-dropdown:disabled */
      color: rgba(0,0,0,.3);
      cursor: default;
      user-select: none;
      border-bottom: 1px dotted rgba(0,0,0,.3);
    }    
    &.select2-container--focus {
      .select2-selection {
        box-shadow: none;
      }
    }
  }
}
/*===End CSS for Select Two===*/

/*Employee Profile Page*/
.epp-img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.epp-label {
  color: rgb(49, 40, 40);
  font-weight: bold;
  display: inline;
  margin: 20px 0 20px -20px;
  word-wrap: break-word;
}
.epp-content {
  display:inline;
  margin: 20px 0 20px -20px;
}
.epp-big-name {
  font-weight: bold;
  font-size: 25px;
  padding: 10px 0;
  text-align: center;
  margin-top: 5px;
}
.epp-group-label{
  color: rgb(168, 168, 168);
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  margin: 20px 0 5px 10px;
  border-width: 0 0 1px 0;
  border-style: solid;
}
.epp-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.epp-col-left {
  width: 25%;
  height:100%;
}
.epp-col-right {
  width: 75%;
  height:100%;
}
.top-col {
  align-items: top;
}
.center-col {
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .epp-col-left { 
    width:100%;
    display: block;
  }
  .epp-col-right { 
    width:100%;
    display: block;
  }
  #btn_show_request_update_information_modal {
    width: 100%;
  }
  .navbar-header-custom {
    margin-left: 0 !important; /* override default navbar_collapse */
    margin-right: 0 !important; /* override default navbar_collapse */
  }
}
/*End of Employee Profile Page*/

/* Custom CSS navbar-header */

.navbar-header-custom {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & .navbar-minimize{
    & a {
      font-size: 18px; 
      line-height: 25px; 
      text-decoration:none; 
      color: $cloudy;
    }
  }

  & .navbar-minimize:last-child {
    margin-left: 10px;
  }

}

.file-upload-container {
  & input[type=file] {
    z-index: 9;
  }
}

.mb-5{
  margin-bottom: 5px;
}

.mx-6{
  margin: 6px 0px !important;
}