/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 60px tall - This is not work properly when zoom in/out */
body {
  padding-top: 60px;
  background: #eeeeee;
  font-weight: 300;
  // font-size: 1.3em;
  line-height: 1.5em;
  color: #3c4858;
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
  border: 0;
  background-color: $white !important;
  color: $trout !important;
}

.navbar-form {
  width: 30%;
}

/*
 * Sidebar
 */

html {
  position: relative;
  min-height: 100%;
}

html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%
}

.auth {
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  padding-top: 0px !important;
  display: flex;
  margin: auto;
}

.login-header {
  background-color: #f1f1f1;
  padding-top: 2em;
  padding-bottom: 1em;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  text-align: center;
}

.login-header img{
  display: block;
  width: 150px;
  margin: auto;
}

.btn-login {
  width: 100%;
}

.wrapper, .row {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.wrapper:before, .wrapper:after,
.column:before, .column:after {
  content: "";
  display: table;
}

.wrapper:after,
.column:after {
  clear: both;
}

#main-modal-container .modal, #reason-reject-modal, #change-password-modal {
  top: 60px !important;
}

/* ============== DISABLE BOOTSTRAP CSS =========== */
/* Hide for mobile, show later */
/* .sidebar {
  display: none;
}
@media (min-width: 768px) {
    .sidebar {
      position: fixed;
      top: 51px;
      bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; Scrollable contents if viewport is shorter than content.
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
  }
} */

/* Sidebar navigation */
/* .nav-sidebar {
  margin-right: -21px; 20px padding + 1px border
  margin-bottom: 20px;
  margin-left: -20px;
}
.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: #428bca;
} */

/*
 * Main content
 */

/* .main {
  padding: 20px;
}
@media (min-width: 768px) {
    .main {
      padding-right: 40px;
      padding-left: 40px;
    }
}
.main .page-header {
  margin-top: 0;
} */

/* ============== END OF DISABLE BOOTSTRAP CSS =========== */

/*
 * off canvas sidebar
 * --------------------------------------------------
 */

.sidebar {
  li.active > a {
    background-color: $menu-active-background;
    color: $green-400 !important;
    // box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px #005c54;
  }
}

@media screen and (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 52px;
    bottom: 0;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: $panel-default-heading-bg;
    float: left;
    min-height: 100%;
    display: table-row; /* This acts as child, and its parent has class display: table; - that help to expand the side bar */
    overflow: auto;
  }

  .wrapper {
    padding-left: 250px;
    transition: all 0.4s ease 0s;
  }

  .wrapper.active {
    padding-left: 0;
  }

  .sidebar .collapse.in {
    display: inline;
  }

  span.hidden-xs {
    float: left;
  }

  .sidebar > .nav li > a {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-align: left;
    color: $trout;
    -webkit-transition: background 300ms, color 300ms;
    -moz-transition: background 300ms, color 300ms;
    -o-transition: background 300ms, color 300ms;
    transition: background 300ms, color 300ms;
    &:hover {
      background-color: $menu-active-background;
    }
  }

  .nav > li > a {
    padding: 10px 15px;
  }

  .main {
    margin-top: 25px;
    margin-bottom: 5px; /* without footer. (with footer: 60px) */
    width: 100%;
    left: 0;
  }

  .active .main {
    padding-left: -255px;
  }

  .row-offcanvas {
    position: relative;
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    display: table;
  }

  .row-offcanvas-left.active {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  .row-offcanvas-left .sidebar-offcanvas {
    position: absolute;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    transition: all 0.4s ease 0s;
  }

  .caret {
    float: right;
    margin-top: 4%;
  }

  .sidebar .visible-xs {
    display: inline !important;
  }

 .sidebar .collapse.in {
    display: inline;
  }

  .sidebar > .nav li > a {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-align: left;
    color: #000000;
    -webkit-transition: background 300ms, color 300ms;
    -moz-transition: background 300ms, color 300ms;
    -o-transition: background 300ms, color 300ms;
    transition: background 300ms, color 300ms;
    &:hover {
      background-color: $menu-active-background;
    }
  }

  .nav > li > a {
    padding: 10px 15px;
  }

  .sidebar > .nav li.active {
    padding: 3px;
  }

  ul.nav li.user-details img.img-user-gb {
    position: absolute; 
    width: 315px;
  }

  .user-details .dropdown-menu {
    min-width: 90px;
    max-width: 140px;
  }
}

@media (max-width: 768px) {
  #banner {
    margin-bottom: 2em;
    text-align: center
  }

  .section-preview .image img {
    width: 100%
  }
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .wrapper {
    padding-left: 0;
    transition: all 0.4s ease 0s;
  }

  .wrapper.active {
    padding-left: 250px;
  }

  .sidebar {
    position: fixed;
    top: 52px;
    bottom: 0;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: $panel-default-heading-bg;
    float: left;
    min-height: 100%;
    display: table-row; /* This acts as child, and its parent has class display: table; - that help to expand the side bar */
    overflow: auto;
  }

  .main {
    margin-top: 25px;
    margin-bottom: 5px; /* without footer. (with footer: 60px) */
    width: 100%;
    left: 0;
  }

  .sidebar .visible-xs {
    display: inline !important;
  }

  .row-offcanvas {
    position: relative;
    left: 0;
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    display: table;
  }

  .row-offcanvas-left.active {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  .wrapper.active {
    padding-left: -250px;
  }

  .row-offcanvas-left .sidebar-offcanvas {
    left: -250px;
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
  }

 .sidebar .collapse.in {
    display: inline;
  }

  .sidebar > .nav li > a {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-align: left;
    color: #000000;
    -webkit-transition: background 300ms, color 300ms;
    -moz-transition: background 300ms, color 300ms;
    -o-transition: background 300ms, color 300ms;
    transition: background 300ms, color 300ms;
    &:hover {
      background-color: $menu-active-background;
    }
  }

  .nav > li > a {
    padding: 10px 15px;
  }

  .sidebar > .nav li.active {
    padding: 3px;
  }

  ul.nav li.user-details img.img-user-gb {
    position: absolute; 
    width: 315px;
  }

  .user-details .dropdown-menu {
    min-width: 90px;
    max-width: 140px;
  }
}

#banner {
  border-bottom: none
}

.page-header h1 {
  font-size: 4em;
}

.bs-docs-section {
  margin-top: 8em;
}

.bs-component {
  position: relative;
}

.bs-component .modal {
  position: relative;
  top: auto;
  right: auto;
  left: auto;
  bottom: auto;
  z-index: 1;
  display: block;
}

.bs-component .modal-dialog {
  width: 90%;
}

.bs-component .popover {
  position: relative;
  display: inline-block;
  width: 220px;
  margin: 20px;
}

#source-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  font-weight: bold;
  padding: 5px 10px;
}

.progress {
  margin-bottom: 10px;
}

.splash {
  padding: 4em 0 0;
  background-color: #141d27;
  color: #fff;
  text-align: center;
}

.splash h1 {
  font-size: 4em;
}

.splash #social {
  margin: 2em 0;
}

.splash .alert {
  margin: 2em 0;
}

.section-tout {
  padding: 4em 0 3em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #eaf1f1;
}

.section-tout .fa {
  margin-right: .5em;
}

.section-tout p {
  margin-bottom: 3em;
}

.section-preview {
  padding: 4em 0 4em;
}

.section-preview .preview {
  margin-bottom: 4em;
  background-color: #eaf1f1;
}

.section-preview .preview .image {
  position: relative;
}

.section-preview .preview .image:before {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  pointer-events: none;
}

.section-preview .preview .options {
  padding: 1em 2em 2em;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-top: none;
  text-align: center;
}

.section-preview .preview .options p {
  margin-bottom: 2em;
}

.section-preview .dropdown-menu {
  text-align: left;
}

.section-preview .lead {
  margin-bottom: 2em;
}

.sponsor {
  text-align: center;
}

.sponsor a:hover {
  text-decoration: none;
}

.infobox .btn-sup {
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  font-size: 15px;
  line-height: 30px;
}

.infobox .btn-sup img {
  opacity: 0.5;
  height: 30px;
}

.infobox .btn-sup span {
  padding-left: 10px;
  position: relative;
  top: 2px;
}

.icons-material .row {
  margin-bottom: 20px;
}

.icons-material .col-xs-2 {
  text-align: center;
}

.icons-material i {
  font-size: 34pt;
}

.icon-preview {
  display: inline-block;
  padding: 10px;
  margin: 10px;
  background: #D5D5D5;
  border-radius: 3px;
  cursor: pointer;
}

.icon-preview span {
  display: none;
  position: absolute;
  background: black;
  color: #EEE;
  padding: 5px 8px;
  font-size: 15px;
  border-radius: 2px;
  z-index: 10;
}

.icon-preview:hover i {
  color: #4285f4;
}

.icon-preview:hover span {
  display: block;
  cursor: text;
}

.btn-round {
  border-radius: 50%;
  font-size: 15px;
  padding: 0;
  color: $cloudy;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
  &:hover, &:active {
    background-color: rgba(0,0,0,.15);
    box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
  }
}

.navbar-minimize {
  padding: 0 3px;
}

@media (min-width: 768px) {
  .navbar-brand-lm {
    margin-left: 0 !important;
    /* Override default _navbar */
  }
}

input.header-search-input {
  display: block;
  padding: 8px 8px 8px 8px;
  width: 100%;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  border: none;
  appearance: textfield;
  font-weight: 400;
  outline: none;
  border-radius: 3px;
  margin-top: -5px;
}

input.header-search-input:focus {
  color: #333;
  background: #fff;
  border-bottom: none !important;
  box-shadow: none !important;
}

.z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

.z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0,0,0,0.22), 0 25px 55px 0 rgba(0,0,0,0.21);
}

.cyan.darken-2 {
  background-color: #0097a7 !important;
  padding-top: 15%;
}

.btn-profile {
  margin-left: 22px;
  text-transform: capitalize;
  padding: 0;
  font-weight: bold;
  font-size: 16px;
  color: #444;
  line-height: 40px;
}

img.responsive-img, video.responsive-video {
  max-width: 100%;
  height: auto;
}

.profile-image {
  width: 65px !important;
  height: 65px !important;
  left: 1.5rem;
  position: absolute;
}

.user-role {
  font-size: 12px;
  color: $accent-color;
  font-weight: bold;
  margin-left: 7.5rem;
  top: 4.5rem;
  position: absolute;
}

#user-menu {
  margin-left: 55px; /* profile's width + 5px */
  white-space: nowrap;
}

.user-details-panel {
  padding: 18px 0px 43px 24px;
}

.circle {
  border-radius: 50%;
}

.mini-button {
  border-radius: 50%;
  padding: 12px;
}

#side-menu {
  padding-bottom: 30%;
}

.checkbox label, .radio label, label {
  font-size: inherit;
  line-height: 1.5em;
  color: #3c4858 !important;
}

.header-search-input {
  color: $divider-color !important;
}

.form-control {
  font-size: $font-size-base;
}

.nav .open > a:focus {
  background-color: inherit;
}

li > a > i {
  float: left;
  width: 15%;
}

.left-submenu a {
  padding-left: 20% !important;
}

.panel-heading {
  margin-left: 2%;
  background-color: $green-400 !important;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  float: left;
  margin: -1.2em 1.5em;
  display: block;
  border-radius: 0.2em;
  padding: 1em 2em;
}

.panel-body {
  padding: 3em 1em 1em 1em !important;
}

/*** Footer ***/
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: $summer-sky;
  color: $default-body-light-color;
}

.footer p {
  font-size: small;
}

.terms-and-conditions {
  color: $trout;
}
/*** End footer ***/

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  background-image: none;
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #9e9e9e !important;
  border-radius: 0;
  outline: none;
  width: 100%;
  margin: 0 0 15px 0;
  padding: 0;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  transition: all .3s
}

.input-field .prefix {
  position: absolute;
  top: 0.5em;
  font-size: 1.5em;
  width: 2em;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -ms-transition: color 0.2s;
  transition: color 0.2s;
}

.input-field .prefix ~ input,
.input-field .prefix ~ label {
  margin-left: 2.2em;
  width: calc(100% - 2.2em);
}

.checkbox-margin-right {
  margin-right: 4px;
}

.form-control {
  height: 25px;
  margin-top: 5px;
  padding: 5px 0;
}

.form-group {
  margin: 2px 0 10px 0 !important;
}

.px20-top-margin {
  margin-top: 20px !important;
}

.px20-bottom-margin {
  margin-bottom: 20px !important;
}

.form-group label.icon-prefix-label {
  margin: 1em 0 0 2em;
}

#form-add-role {
  margin-bottom: 28px;
}

.overflow-hidden {
  overflow:hidden;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

.loading-square {
  height: 50px;
  width: 50px;
  position:fixed;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: pink;
  -webkit-animation: loading-square 5s infinite linear;
  animation: loading-square 5s infinite linear;
}

@-webkit-keyframes loading-square {
  0% { 
     -webkit-transform: perspective(180px) rotateX(0deg) rotateY(0deg);
     background-color: #F4D03F;
  }
  25% { 
     -webkit-transform: perspective(180px) rotateX(-180.1deg) rotateY(0deg);
     background-color: #52B3D9;
  }
  50% { 
     -webkit-transform: perspective(180px) rotateX(-180deg) rotateY(179.9deg);
     background-color: #EC644B;
  }
  75% { 
     -webkit-transform: perspective(180px) rotateX(-360deg) rotateY(179.9deg);
     background-color: #68C3A3;
  }
  100% { 
     -webkit-transform: perspective(180px) rotateX(-360deg) rotateY(360deg);
     background-color: #F4D03F;
  }
}

@keyframes loading-square {
  0% { 
     transform: perspective(180px) rotateX(0deg) rotateY(0deg);
     -webkit-transform: perspective(180px) rotateX(0deg) rotateY(0deg);
     background-color: #F4D03F;
  }
  25% { 
     transform: perspective(180px) rotateX(-180.1deg) rotateY(0deg);
     -webkit-transform: perspective(180px) rotateX(-180.1deg) rotateY(0deg);
     background-color: #52B3D9;
  }
  50% { 
     transform: perspective(180px) rotateX(-180deg) rotateY(179.9deg);
     -webkit-transform: perspective(180px) rotateX(-180deg) rotateY(179.9deg);
     background-color: #EC644B;
  }
  75% { 
     transform: perspective(180px) rotateX(-180deg) rotateY(179.9deg);
     -webkit-transform: perspective(180px) rotateX(-360deg) rotateY(179.9deg);
     background-color: #68C3A3;
  }
  100% { 
     transform: perspective(180px) rotateX(0deg) rotateY(360deg);
     -webkit-transform: perspective(180px) rotateX(-360deg) rotateY(360deg);
     background-color: #F4D03F;
  }
}
.modal-header {
  background-color: inherit !important;
}

.bootstrap-dialog-title {
  color: inherit !important;
}

.text-danger {
  color: #bf5329 !important;
}

table {
  width:100%;
}

td {
  word-wrap:break-word;
}

.bootstrap-dialog-footer-buttons {
  margin-right: 2%;
}

@media (max-width: 768px) {
  .btn-responsive {
    padding:4px 4px !important;
    font-size:80% !important;
    line-height: 1;
    border-radius:3px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .btn-responsive {
    padding:4px 9px !important;
    font-size:90% !important;
    line-height: 1.2;
  }
}

.collapsible {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  margin: 0.5em 0 1em 0;
  /* box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12); */
}

.panel.panel-collapsible {
  box-shadow: none;
  border-radius: 0;
}

.collapsible-header {
  min-height: 2em;
  padding: 1em 1em;
  border-bottom: 1px solid #ddd;
  display: block;
  line-height: 2em;
  cursor: pointer;
}

.collapsible-body {
  background: #FAFAFA;
  border-bottom: 1px solid #ddd;
}

.collapsible-header h4:after,
.sidebar .nav li  a[data-toggle="collapse"]:after {
  font-family: 'Glyphicons Halflings';
  float: right;
  content: '\e114';
}

.collapsible-header.collapsed h4:after,
.sidebar .nav li a[data-toggle="collapse"].collapsed:after {
  float: right;
  content: '\e080';
}

.panel-group.collapsible .panel + .panel {
  margin-top: 0;
}

.jsgrid-cell {
  padding-top: 0.01em !important;
  padding-bottom: 0.01em !important;
}

.intermittent {
    border: 1px solid #ddd;
    background-color: #fff;
    color: #fff;
    padding-left: 0;
    padding-right: 0;
}

.date-header {
  background-color: #455a64;
  height: 2.3em;
  border: 1px solid #455a64;
  padding-left: 15px;
}

.date-detail {
  padding-left: 15px;
  padding-right: 15px;
}

.nav-pills > li > a {
  border-radius: 30px;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
  color: #555555;
  transition: all .3s;
}

.nav-pills > li > a:hover {
  background-color: rgba(200, 200, 200, 0.2);
}

.nav-pills.nav-pills-warning > li.active > a, .nav-pills.nav-pills-warning > li.active > a:focus, .nav-pills.nav-pills-warning > li.active > a:hover {
  background-color: #ff9800;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
}

.nav-tabs > li > a:focus {
  background-color: red;
}

.classic-tabs.tabs-primary {
    background-color: $green-400;
}

.classic-tabs.tabs-primary li {
  padding: 5px 5px 0 5px;
}

.classic-tabs li:first-child {
    margin-left: 56px;
}

.nav-tabs > li > a {
  border: 0 !important;
  color: rgba(255,255,255,.7) !important;
  font-weight: bold;
  &:hover, &:focus {
    background-color: rgba(0, 0, 0, 0.06) !important;
  }
}

.nav-tabs > li.active > a {
  text-decoration: none;
  color: #8C8C8C !important;
  font-weight: bold;
  background-color: #ffffff !important;
}

.fixed-control {
  position: absolute;
  min-height: 35px;
}
.jsgrid-header-row {
  height: 70px;
}
.jsgrid-pager-container {
  margin-top: 1%;
}

.jsgrid-control-field .jsgrid-search-mode-button {
  margin-top: 5px;
  margin-left: -10px;
  position: absolute;
}

.card-stats {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 25px 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  color: rgba(0,0,0, 0.87);
  background: #fff;

  .title {
    margin: 0;
  }

  .card-header {
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    margin: -20px 15px 0;
    border-radius: 3px;
    padding: 15px;
    background-color: #999999;
    float: left;
    text-align: center;
    span {
      font-size: 30px;
      line-height: 40px;
      min-width: 40px;
      height: 40px;
    }
    .title {
      color: #FFFFFF;
    }

    .category {
      margin-bottom: 0;
      color: rgba(255, 255, 255, 0.62);
    }
  }

  .card-content {
    text-align: right;
    padding-top: 10px;
    padding: 15px 20px;
    .category {
      margin-bottom: 0;
    }
  }

  .card-footer {
    margin: 0 20px 10px;
    padding-top: 10px;
    border-top: 1px solid #eeeeee;
    .stats {
      line-height: 22px;
      color: #999999;
      font-size: 12px;
      display: inline-block;
    }
  }
}

.material-icons {
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.card-stats [data-background-color="purple"] {
  background: linear-gradient(90deg, #8e24aa, #d862ec);
  box-shadow: 0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2);
}

.card-stats [data-background-color="blue"] {
  background: linear-gradient(90deg, #00acc1, #46d3e6);
  box-shadow: 0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2);
}

.card-stats [data-background-color="green"] {
  background: linear-gradient(90deg, #269100, #9DE262);
  box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
}

.card-stats [data-background-color="orange"] {
  background: linear-gradient(90deg, #fb8c00, #f0ad48);
  box-shadow: 0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2);
}

.card-stats [data-background-color="red"] {
  background: linear-gradient(90deg, #e53935, #f36664);
  box-shadow: 0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2);
}

.card-stats [data-background-color] {
  color: #FFFFFF;
}

.card-stats [data-background-color] a {
  color: #FFFFFF;
}

.jsgrid-edit-row>.jsgrid-cell, .jsgrid-row>.jsgrid-cell, .jsgrid-alt-row>.jsgrid-cell {
  height: 100%;
}

.jsgrid-pager {
  float: right;
}

.calendar-legend {
  margin-top: 5px;
}

dl {
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px 15px;
}

dt, dd {
  display: inline;
}

dd {
  padding-left: 3px;
}

.legend {
  padding-left: 15px;
}

.draft {
  border: 1px solid #E8C815;
  background-color: #CBB956;
}

.approved-pm {
  border: 1px solid #007D39;
  background-color: #16A765;
}

.rejected-pm {
  border: 1px solid #B71C1C;
  background-color: #E53935;
}

.wait-approval {
  border: 1px solid #1587BD;
  background-color: #bcd7ee;
}

.rejected-hra {
  border: 1px solid #D50000;
  background-color: #FF1744;
}

.approved-hra {
  border: 1px solid #304FFE;
  background-color: #3D5AFE;
}

.rejected-hrd {
  border: 1px solid #EF6C00;
  background-color: #F57C00;
}

.approved-hrd {
  border: 1px solid #00838F;
  background-color: #00ACC1;
}

.cancelled {
  border: 1px solid #424242;
  background-color: #424242;
}

.error {
  color: red !important;
}

.fc-day-grid-event .fc-time {
  display: none;
}

.email-us,
.email-us:hover,
.email-us:focus {
  color: inherit;
}

.high-light-no-reference-doc {
  background-color: #FFDC00;
}

.lacking-document {
  color: #0074D9;
}

/*** Login Page ***/

@font-face {
  font-family: 'Poppins-Regular';
  src: url(/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url(/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url(/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url(/fonts/Montserrat-Bold.ttf);
}

.content-page {
  margin: auto;
}

.panel-login {
  padding: 9em 0em 9em 0em !important;
}

.login-form {
  background: #fff;
  border-radius: 10px;
  overflow: hidden; 
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  // background: radial-gradient(circle farthest-corner at -5% 50% , #269100 ,#9DE262 50%, #FFFFFF 50%);
}

.login-page-circle {
  position: fixed; 
  bottom: -40%;
  left: -12%;
  width: 35%;
}

.login-page-elilipse {
  position:fixed; 
  top:-40%;
  right:-12%;
  width: 35%;
}

.login-img {
  width: 175px;
  position: absolute;;
  margin: -20% 29%;  
}

img.login-icon {
  width: 100%;
  margin: auto 4%;
}

.background-image-login {
  width: 55%;
  position: absolute;
  margin-left: -1%;
}
.login-error-form .background-image-login {
  width: 62% !important;
  margin-left: -9% !important;
}
.login-form-title {
  font-family: Poppins-Bold;
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 54px;
}

.login-form .form-group {
  padding-bottom: 0px;
}

.input-login {
  font-family: Poppins-Medium;
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
  box-sizing: border-box;
  display: block;
  width: 100%;
  background: #e6e6e6;
  height: 50px;
  border: none;
  border-radius: 25px;
  padding: 0 30px 0 68px;
}

.input-login:-webkit-autofill {
  font-family: Poppins-Medium;
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
  box-sizing: border-box;
  display: block;
  width: 100%;
  background: #e6e6e6;
  height: 50px;
  border: none;
  border-radius: 25px;
  padding: 0 30px 0 68px;
}

.alert-login {
  font-family: Poppins-Medium;
  font-size: 15px;
}

/*[ Focus ]*/
.focus-input-login {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: #269100;
}

.input-login:focus + .focus-input-login {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

.symbol-input-login {
  font-size: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #666666;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input-login:focus + .focus-input-login + .symbol-input-login {
  color: #269100;
  padding-left: 28px;
}

/*[ Button ]*/

.login-form-btn {
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff !important;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 25px;
  background: linear-gradient(90deg, #269100, #9DE262) !important;
}

.login-form-btn:hover {
  background: #333333 !important;
}

a.btn-success, button.btn-success {
  background-color: #269100 !important;
}

button.btn-success:hover {
  background-color: #428400 !important;
}

a.btn-success:hover {
  background-color: #428400 !important;
}

/*[ Responsive ]*/

@media (min-width: 992px) and (max-width: 1199px) {
  img.login-img {
    margin: -20% 24%;
  }
  img.background-image-login {
    width: 64%;
    margin-left: -10% !important;
  }
  .login-error-form .background-image-login {
    width: 73% !important;
    margin-left: -19% !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  img.login-img {
    width: 250px;
    padding-top: 5%;
  }
  .login-page-circle {
    position: fixed;
    bottom: -30%;
    left: -30%;
    width: 50%;
  }
  .login-page-elilipse {
    position: fixed;
    top: -30%;
    right: -20%;
    width: 50%;
  }
  .login-pic {
    display: none;
  }
  .login-form {
    background: #fff;
  }
  img.background-image-login {
    display: none;
  }
}

@media (max-width: 768px) {
  img.login-img {
    width: 200px;
    position: relative;
    display: block;
    margin: 5% auto;
  }
  .panel-login {
    padding: 1em 1em 1em 1em !important;
  }
  .login-page-circle {
    position: fixed;
    bottom: -30%;
    left: -55%;
    width: 100%;
  }
  .login-page-elilipse {
    position:fixed;
    top: -30%;
    right: -48%;
    width: 100%;
  }
  .login-pic {
    display: none;
  }
  .login-form {
    background: #fff;
  }
  img.background-image-login {
    display: none;
  }
}
@media (max-width: 576px) {
  .input-login, .input100:-webkit-autofill, .login-form-btn, .alert-login {
    font-size: 12px;
  }
  img.login-img {
    display: block;
    margin: 10% auto;
  }
}
/*** End Login Page  ***/
